p {margin: 0px; padding: 0px; margin-bottom: 24px; }

.alert{font-size: 13px;line-height: 18px;font-weight:400;border: 0;border-radius: 6px;padding: 12px 57px 10px;margin-bottom: 10px;}
.alert-icon{position:absolute;left: 11px;top: 11px;}
.alert h5 { font-size: 15px; line-height: 18px; font-weight: 600; margin-bottom: 0px; }
.alert p{margin-bottom:0;}
.alert-right{padding-left:0;margin-left:auto;position:absolute;top: 15px;right: 24px;}
.alert-error{background:#feeaea; color:#fa3434;}
.alert-info{background:#f1f8ff; color:#007bff;}
.alert-success{background:#e5f7e7; color:#00b517;}
.alert-warning{background:#fff3e7; color:#ff9017;}
.alert.alert-sm { padding: 10px 36px; }
.alert.alert-sm h5{font-size: 13px;}
.alert.alert-sm .alert-icon { top: 8px; left: 10px; width: 18px; }
.alert.alert-sm .alert-right { top: 7px; right: 13px; }
.alert-container{position:fixed;top:50px;right:20px;z-index:99;}
.alert.alert-white{background:#fff;border:0;box-shadow: 0 5px 15px rgba(0,0,0,0.1);color:#000;font-size:15px;line-height:20px;padding:22px 20px 22px 60px;font-weight:400;}
.alert.alert-white p{margin-bottom:0;}
.alert-success.alert-white .alert-icon{color:#26CA97;}
.alert-white .alert-icon{margin-right:0;font-size:20px;position:absolute;left:18px;top:23px;}
.alert-white .alert-right{padding-left:0;margin-left:auto;position:absolute;top:18px;right:25px;font-size:25px;}
.alert-white .alert-close{font-size:16px;line-height:24px;font-weight:300;}
.alert.alert-white.alert-danger .alert-icon{color:#BA0303;}
.modal{z-index:9999999999;}
.modal-dialog{max-width:525px;margin-top:45px;}
.modal-content{border:0;border-radius:8px;box-shadow:0 5px 15px rgba(0,0,0,0.1);padding:12px 20px;}
.modal-header{border-bottom:none;position:relative;padding:0;}
.modal-body{padding:15px 15px; padding-top: 0px}
.varification-popup{padding:0 20px 35px;}
.varification-popup h4{line-height:24px;margin-bottom:30px;}
.varification-popup p{font-size:16px;line-height:20px;}
.load-msg{position:relative;color:#8798AD;font-style:italic;font-size:16px;line-height:20px;font-weight:400; padding-left: 40px;}
.load-msg .btn-loader{left:0;}
.modal-backdrop:before{content:'';width:100%;height:100%;background:#fff;position:absolute;left:0;top:0;opacity:0.85;}
.modal-backdrop:after{content:'';width:100%;height:100%;background:#2e5bff;position:absolute;left:0;top:0;opacity:0.08;}
/*.varify-has-sucess h4{color:#26CA97;}
.varify-has-failed h4{color:#BA0303;}*/
.modal-alert-box p{margin-bottom:10px;font-weight:400;}
.modal-action-btn{margin-top:30px;display:flex;align-items:flex-end;justify-content:flex-end;}
.modal-action-btn .btn + .btn { margin-left: 10px;}
.alert-close {opacity: .75;}
.btn-delete{background:#BA0303;color:#fff;}
.btn-delete:hover,.btn-delete:focus{background:#BA0303;color:#fff;}
/* =================global css ends here================= */
.Toastify__toast-container {
  width: auto !important;
}
.alert.alert-white {
  border-radius: 4px !important;
}
.fa-exclamation-circle.alert-red {
  color: #ba0303 !important;
}
.alert-close {
  opacity: 0.75;
}
